import { useState, useRef, useEffect } from "react"

import './styles.css'
import liquidoLogo from '../../assets/images/liquido-logo-white.png'
import menuIcon from '../../assets/images/icon-menu.png'
// import CTABtn from "../CTABtn"

function TopNavBar() {
    const [menuMobileOpen, setMenuMobileOpen] = useState(false)

    const setScrollPosY = () => {
        setTimeout(() => window.scrollTo({ top: window.scrollY - 150, behavior: "smooth" }), 680)
    }

    const toggleMobileMenu = () => {
        const isOpened = !menuMobileOpen
        setMenuMobileOpen(isOpened)
    }

    const useOutsideClick = (callback) => {
        // https://www.robinwieruch.de/react-hook-detect-click-outside-component/
        const ref = useRef()

        useEffect(() => {
            const handleClick = (event) => {
                // console.log(event.target)
                const outside = ref.current && !ref.current.contains(event.target)
                // console.log("outside: ", outside)
                // console.log("menuMobileOpen: ", menuMobileOpen)
                if (outside && menuMobileOpen) {
                    callback()
                }
            }

            document.addEventListener('click', handleClick)

            return () => {
                document.removeEventListener('click', handleClick)
            }
        }, [ref, callback])

        return ref
    }

    const handleClickOutside = () => {
        toggleMobileMenu()
    }

    const ref = useOutsideClick(handleClickOutside)

    return (
        <div ref={ref}>
            <nav className="top-nav" id="top-nav-bar">
                <div><a href="#section1"><img src={liquidoLogo} alt="Liquido Logo" /></a></div>
                <div className="navbar">
                    <a href="#1" className="icon" onClick={toggleMobileMenu}>
                        <img src={menuIcon} alt="Menu Icon" />
                    </a>
                    <a href="#section3" onClick={setScrollPosY}>PAGAMENTOS</a>
                    <a href="#section6" onClick={setScrollPosY}>MERCADOS</a>
                    <a href="#section4" onClick={setScrollPosY}>SEGMENTOS</a>
                    <a href="https://www.liquido.com/blog" target="_blank" rel="noreferrer">CONTEÚDOS</a>
                    <a href="#5">INSTITUCIONAL</a>
                    {/* <CTABtn /> */}
                </div>
            </nav>
            <nav className={`top-nav-mobile ${!menuMobileOpen ? 'not-display' : ''}`}>
                <div className="navbar-mobile">
                    <a href="#1" className="icon" onClick={toggleMobileMenu}>
                        <img src={menuIcon} alt="Menu Icon" />
                    </a>
                    <a href="#section3" className="mobile-menu-item" onClick={setScrollPosY}>PAGAMENTOS</a>
                    <a href="#section6" className="mobile-menu-item" onClick={setScrollPosY}>MERCADOS</a>
                    <a href="#section4" className="mobile-menu-item" onClick={setScrollPosY}>SEGMENTOS</a>
                    <a href="https://www.liquido.com/blog" target="_blank" rel="noreferrer" className="mobile-menu-item">CONTEÚDOS</a>
                    <a href="#5" className="mobile-menu-item">INSTITUCIONAL</a>
                    {/* <CTABtn /> */}
                </div>
            </nav>
        </div>
    )
}

export default TopNavBar