import React, { useState, useEffect } from 'react'

import './App.css'
import section2Img from './assets/images/section2-image.png'
import saasClouldImg from './assets/images/saas_clould.png'
import gamingImg from './assets/images/gaming.png'
import streamingImg from './assets/images/streaming.png'
import digitalAdImg from './assets/images/digital-ad.png'
import travelOtasImg from './assets/images/travel-otas.png'
import sharedEconomyImg from './assets/images/shared-economy.png'
import ecommerceImg from './assets/images/ecommerce.png'
import downloadAppImg from './assets/images/download-app-2.png'
import liquidoLogoBlueImg from './assets/images/liquido-logo-blue.png'
// import arrowSendImg from './assets/images/arrow-send.png'

import TopNavBar from './components/TopNavBar'
// import CTABtn from './components/CTABtn'
import SlideShow from './components/SlideShow'
// import KnowMoreBtn from './components/KnowMoreBtn'

function App() {
  const [scrollPosY, setScrollPosY] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosY(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div id="root-container">
      {/* <div class="content-container"> */}
      <header className={`top-navigation ${scrollPosY > 120 ? 'scrolled' : ''}`}>
        <TopNavBar />
      </header>
      <main>
        <section id="section1">
          <h1>A SOLUÇÃO DE PAGAMENTOS PERFEITA PARA NEGÓCIOS INTERNACIONAIS EM <span>AMBIENTES DINÂMICOS</span></h1>
          {/* <CTABtn size='large' /> */}
        </section>
        <section id="section2">
          <div>
            <h1>UMA PLATAFORMA DE PAGAMENTOS PODEROSA E EXCLUSIVA PARA UMA OPERAÇÃO GLOBAL</h1>
            <p>Impulsione seu crescimento e facilite a conquista de novos mercados com nossa solução de pagamentos dinâmica. Líderes em inovação, garantimos eficiência e abrangência máxima em processamentos de pagamentos, sempre com excelência no atendimento.</p>
          </div>
          <img src={section2Img} alt='Section 2' />
        </section>
        <section id="section3">
          <h1 className="title1"><span>PAGAMENTOS FACILITADOS</span> <br />PARA QUALQUER NEGÓCIO</h1>
          <SlideShow />
        </section>
        <section id="section4">
          <h1>ESTRATÉGIAS PERSONALIZADAS <br /> PARA O SUCESSO ONLINE</h1>
          <div className='images-container'>
            <img src={saasClouldImg} alt="Saas & Clould" />
            <img src={gamingImg} alt="Gaming" />
            <img src={streamingImg} alt="Streaming" />
            <img src={digitalAdImg} alt="Anúncios Digitais" />
            <img src={travelOtasImg} alt="Travel & Otas" />
            <img src={sharedEconomyImg} alt="Economia Compartilhada" />
            <img src={ecommerceImg} alt="E-commerce" />
          </div>
          {/* <CTABtn size='large' /> */}
        </section>
        <section id="section5">
          <h1>PROCESSE PAGAMENTOS COM FACILIDADE E OBTENHA <br />VANTAGENS EM MERCADOS DE CRESCIMENTO ELEVADO</h1>
          {/* <CTABtn size='large' /> */}
        </section>
        <section id="section6">
          <h1>AMPLA COBERTURA OPERACIONAL PARA <br />PROSPERAR EM MERCADOS EM CRESCIMENTO</h1>
          <p className='bg-transparent'>A América Latina, a África e a Índia estão impulsionando a economia global. O aumento no poder de compra dos consumidores nestas regiões geram oportunidades únicas de expansão para empresas globais e, com a Liquido, você pode vender nestes lugares, sem precisar abrir uma entidade local. Atualmente processamos e gerenciamos pagamentos em 25 países: 15 na América Latina, nove na África, e um no Sul da Ásia (Índia).</p>
          <div className='cards-container'>
            <div className='card'>
              <div>
                <h2>BRASIL</h2>
                <p>O e-commerce no Brasil, projetando forte crescimento para 2024, enfatiza a omnicanalidade, experiência mobile e personalização da compra. Importância dos pagamentos diversificados e logística eficiente destacam-se como estratégias chave para satisfazer consumidores exigentes e impulsionar o setor.</p>
              </div>
              {/* <KnowMoreBtn /> */}
            </div>
            <div className='card'>
              <div>
                <h2>MÉXICO</h2>
                <p>O e-commerce no México continuará crescendo em 2024, impulsionado por expectativas de consumidores mais altas e a adoção de novas tecnologias. A experiência de usuário aprimorada, inteligência artificial, e realidade aumentada serão fundamentais. Além disso, mudanças nos hábitos de consumo e a importância da segurança nas transações destacarão o dinamismo do setor.</p>
              </div>
              {/* <KnowMoreBtn /> */}
            </div>
            <div className='card'>
              <div>
                <h2>COLÔMBIA E CHILE</h2>
                <p>Para 2024, o e-commerce no Chile e Colômbia mira crescimento com inovações e sustentabilidade. No Chile, vendas podem alcançar US$11,5 bi, impulsionadas por IA e realidade aumentada. Na Colômbia, estratégias omnicanal e logística verde destacam-se para atender consumidores de forma eficiente.</p>
              </div>
              {/* <KnowMoreBtn /> */}
            </div>
          </div>
        </section>
        <section id="section7">
          <div className='left'>
            <h1>BAIXE O NOSSO APP E ENTRE EM CONTATO CONOSCO PARA COMEÇAR A USAR!</h1>
            <a href='#1'>Baixe o app</a>
          </div>
          <div className='right'>
            <img src={downloadAppImg} alt="Download App" />
          </div>
        </section>
      </main>

      <footer>
        <section id="section8">
          <div className='logo-blue-container'><img src={liquidoLogoBlueImg} alt="Liquido Logo Blue" /></div>
          <nav className='nav-bar'>
            <a href="#1">PAGAMENTOS</a>
            <a href="#2">MERCADOS</a>
            <a href="#3">SEGMENTOS</a>
            <a href="#4">CONTEÚDOS</a>
            <a href="#5">INSTITUCIONAL</a>
          </nav>
          <nav className='social-media-nav'>
            <h4>Siga-nos nas redes sociais</h4>
            <div>
              <a href="#1" className="fa fa-twitter"><span></span></a>
              <a href="#1" className="fa fa-instagram"><span></span></a>
              <a href="#1" className="fa fa-facebook"><span></span></a>
            </div>
            {/* <h4>Assine a nossa Newsletter</h4>
            <div className='form'>
              <input type="email" />
              <a href="#1"><img src={arrowSendImg} alt=">" /></a>
            </div> */}
          </nav>
          <div className='payment-methods'>
            <h4>Métodos de Pagamento</h4>
            <ul>
              <li><span>Cartões de crédito</span></li>
              <li><span>Carteira Digital</span></li>
              <li><span>PIX</span></li>
              <li><span>Boleto</span></li>
              <li><span>Depósito bancário</span></li>
              <li><span>Lotérica</span></li>
            </ul>
          </div>
        </section>
        <div id="copyright">
          Termos e aviso de privacidade. Envie-nos seu feedback. © 2024, Liquido
        </div>
      </footer>
    </div>
  )
}

export default App
