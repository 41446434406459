import React, { useState } from 'react'

import './styles.css'
import s3BlueImg from '../../assets/images/section3-img-blue.png'
import s3PayinsSmartphoneImg from '../../assets/images/section3-payins-smartphone.png'
import s3PayoutImg from '../../assets/images/section3-payout.png'
import s3FxImg from '../../assets/images/section3-fx.png'
import s3BaasImg from '../../assets/images/section3-baas.png'

// import KnowMoreBtn from '../KnowMoreBtn'


function SlideShow() {

    let [slideIndex, setSlideIndex] = useState(0)

    const changeSlideIndex = (sldIndex) => {
        setSlideIndex(sldIndex)
    }

    return (
        <div id="slides-container">
            <div className="tabs-items-container">
                {/* <p>Transforme seu negócio em um hub financeiro completo. Ofereça serviços bancários personalizados aos seus clientes, expandindo suas ofertas e fortalecendo a fidelidade do cliente. Transforme seu negócio em um hub financeiro completo. Ofereça serviços bancários personalizados aos seus clientes, expandindo suas ofertas e fortalecendo a fidelidade do cliente.</p> */}
                <ul>
                    <li><span className="tab-item" onClick={() => changeSlideIndex(0)}>PAYINS</span></li>
                    <li><span className="tab-item" onClick={() => changeSlideIndex(1)}>PAYOUTS</span></li>
                    <li><span className="tab-item" onClick={() => changeSlideIndex(2)}>FX</span></li>
                    <li><span className="tab-item" onClick={() => changeSlideIndex(3)}>BAAS</span></li>
                </ul>
            </div>
            <div className="slideshow-container">
                <div className={`slide-show-tab fade ${slideIndex !== 0 ? 'not-display' : ''}`}>
                    <div className="img-container">
                        <img src={s3BlueImg} alt="Payins Blue Rectangle" />
                        <img src={s3PayinsSmartphoneImg} className="img-over" alt="Payins Smartphone" />
                    </div>
                    <div className="text-content">
                        <h2>PAYINS</h2>
                        <h1>Potencialize seu negócio com soluções de pagamento simples e eficazes</h1>
                        <p>Simplifique pagamentos com nossa plataforma intuitiva e segura. Receba pagamentos de clientes em várias moedas, de forma rápida e conveniente, garantindo uma experiência de compra perfeita.</p>
                        {/* <KnowMoreBtn /> */}
                    </div>
                </div>

                <div className={`slide-show-tab fade ${slideIndex !== 1 ? 'not-display' : ''}`}>
                    <div className="img-container">
                        <img src={s3BlueImg} alt="Payout Blue Rectangle" />
                        <img src={s3PayoutImg} className="img-over" alt="Payout" />
                    </div>
                    <div className="text-content">
                        <h2>PAYOUTS</h2>
                        <h1>Automatize sua operação com transferências de fundos rápidas e seguras</h1>
                        <p>Facilite pagamentos e transferências de fundos para funcionários, fornecedores e parceiros nos países em que atuamos. Uma solução eficiente e confiável para gerenciar suas operações financeiras de forma transparente.</p>
                        {/* <KnowMoreBtn /> */}
                    </div>
                </div>

                <div className={`slide-show-tab fade ${slideIndex !== 2 ? 'not-display' : ''}`}>
                    <div className="img-container">
                        <img src={s3BlueImg} alt="FX Blue Rectangle" />
                        <img src={s3FxImg} className="img-over" alt="FX" />
                    </div>
                    <div className="text-content">
                        <h2>FX</h2>
                        <h1>Desbloqueie oportunidades globais e otimize suas operações de câmbio</h1>
                        <p>Otimize suas operações de câmbio com nossa plataforma. Converta moedas de forma rápida e precisa, aproveitando as melhores taxas de câmbio para maximizar seus lucros e minimizar riscos.</p>
                        {/* <KnowMoreBtn /> */}
                    </div>
                </div>

                <div className={`slide-show-tab fade ${slideIndex !== 3 ? 'not-display' : ''}`}>
                    <div className="img-container">
                        <img src={s3BlueImg} alt="BAAS Blue Rectangle" />
                        <img src={s3BaasImg} className="img-over" alt="BAAS" />
                    </div>
                    <div className="text-content">
                        <h2>BAAS</h2>
                        <h1>Engage seus clientes com soluções bancárias personalizadas</h1>
                        <p>Transforme seu negócio em um hub financeiro completo. Ofereça serviços bancários personalizados aos seus clientes, expandindo suas ofertas e fortalecendo a fidelidade do cliente.</p>
                        {/* <KnowMoreBtn /> */}
                    </div>
                </div>
            </div>
            <footer className="dots-container">
                <span className={`dot ${slideIndex === 0 ? 'selected' : ''}`} onClick={() => changeSlideIndex(0)}></span>
                <span className={`dot ${slideIndex === 1 ? 'selected' : ''}`} onClick={() => changeSlideIndex(1)}></span>
                <span className={`dot ${slideIndex === 2 ? 'selected' : ''}`} onClick={() => changeSlideIndex(2)}></span>
                <span className={`dot ${slideIndex === 3 ? 'selected' : ''}`} onClick={() => changeSlideIndex(3)}></span>
            </footer>
        </div>
    )
}

export default SlideShow